<template>
  <div>
    <section class="hucha-crear">
      <div class="bb-container">
        <Notification
          v-if="showErrorNotification"
          tipo="error"
          @close-notification="showErrorNotification = false"
        >
          {{ errorMessage }}
        </Notification>
        <div class="hucha-crear__cols">
          <div class="hucha-crear__left">
            <div class="hucha-crear__pre">{{ $t("piggybank.mypiggybank", lang) }}</div>
            <!-- <div class="hucha-crear__header">{{ $t("piggybank.yourpiggybank", lang) }}</div> -->
            <div class="hucha-crear__description">
              {{ $t("piggybank.yourpiggybankdescriptionPrivate", lang) }}
            </div>
            <aside class="hucha-crear__actions">
              <!-- <ModalCrear v-if="isAuthenticated" @creation-error="showError" /> -->
              <modal-disabled v-if="isAuthenticated"></modal-disabled>
              <router-link v-else :to="$t('routes.signup', lang)" class="button button--primary">
                {{ $t("piggybank.ctaStart", lang) }}
              </router-link>
              <a href="#comoFunciona" class="link link--underline link--bold">
                {{ $t("piggybank.howDoesItWork", lang) }}
              </a>
            </aside>
          </div>
          <picture class="hucha-crear__cover" v-if="lang === 'fr-FR' || $i18n.locale === 'fr-FR'">
            <img
              srcset="../../assets/img/covers/cover-hucha-inicio.fr-FR.png"
              :alt="$t('piggybank.ctaStartUser', lang)"
            />
          </picture>
          <picture class="hucha-crear__cover" v-else>
            <img
              srcset="../../assets/img/covers/cover-hucha-inicio2x.jpg"
              alt="Crear Mi Hucha"
            />
            <source
              srcset="../../assets/img/covers/cover-hucha-inicio.jpg"
              alt="Crear Mi Hucha"
              media="(max-width: 991px)"
            />
          </picture>
        </div>
      </div>
    </section>

    <section class="shucha shucha--grey">
      <div class="bb-container">
        <div class="shucha__container">
          <header class="shucha__head">
            <div class="shucha__title">{{ $t("piggybank.getGiftsAndMoney", lang) }}</div>
            <div class="shucha__subtitle">
              {{ $t("piggybank.getGiftsAndMoneySubtitle", lang) }}
            </div>
          </header>
          <main class="shucha__main">
            <div class="shucha-feat">
              <div class="shucha-feat__items">
                <div class="shucha-feat__item">
                  <div class="shucha-feat__cover">
                    <img v-show="lang === 'es-ES' || lang === 'es' " src="../../assets/img/ico/ico-color-gratis.svg" alt="" />
                    <img v-show="lang === 'fr-FR' " src="../../assets/img/ico/ico-color-gratuit.svg" alt="" />
                    <img v-show="lang === 'it-IT' " src="../../assets/img/ico/ico-color-gratis-it.svg" alt="" />
                  </div>
                  <div class="shucha-feat__main">
                    <div class="shucha-feat__title">{{ $t("piggybank.getGiftsAndMoneyReasonFree", lang) }}</div>
                    <div class="shucha-feat__description" v-html="$t('piggybank.getGiftsAndMoneyReasonFreeText', lang)">
                    </div>
                  </div>
                </div>
                <div class="shucha-feat__item">
                  <div class="shucha-feat__cover">
                    <img src="../../assets/img/ico/ico-color-hucha-money.svg" alt="" />
                  </div>
                  <div class="shucha-feat__main">
                    <div class="shucha-feat__title">{{ $t("piggybank.getGiftsAndMoneyReason1", lang) }}</div>
                    <div class="shucha-feat__description">
                      {{ $t("piggybank.getGiftsAndMoneyReason1Text", lang) }}
                    </div>
                  </div>
                </div>
                <div class="shucha-feat__item">
                  <div class="shucha-feat__cover">
                    <img src="../../assets/img/ico/ico-color-card-secure.svg" alt="" />
                  </div>
                  <div class="shucha-feat__main">
                    <div class="shucha-feat__title">{{ $t("piggybank.getGiftsAndMoneyReason2", lang) }}</div>
                    <div class="shucha-feat__description">
                      {{ $t("piggybank.getGiftsAndMoneyReason2Text", lang) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </section>
    <section class="shucha">
      <div class="bb-container">
        <div class="shucha__container">
          <header class="shucha__head">
            <div class="shucha__title">{{ $t("piggybank.greatForFamily", lang) }}</div>
          </header>
          <main class="shucha__main">
            <div class="shucha-tips">
              <div class="shucha-tips__items">
                <div class="shucha-tips__item shucha-tips__item--inline">
                  <div class="shucha-tips__ico">
                    <img src="../../assets/img/ico/ico-primary-puzzle.svg" alt="" />
                  </div>
                  <div class="shucha-tips__main">
                    <div class="shucha-tips__title">{{ $t("piggybank.greatForFamilyReason1", lang) }}</div>
                    <div class="shucha-tips__subtitle">
                      {{ $t("piggybank.greatForFamilyReason1Text", lang) }}
                    </div>
                  </div>
                </div>
                <div class="shucha-tips__item shucha-tips__item--inline">
                  <div class="shucha-tips__ico">
                    <img src="../../assets/img/ico/ico-primary-eurosend.svg" alt="" />
                  </div>
                  <div class="shucha-tips__main">
                    <div class="shucha-tips__title">
                      {{ $t("piggybank.greatForFamilyReason2", lang) }}
                    </div>
                    <div class="shucha-tips__subtitle">
                      {{ $t("piggybank.greatForFamilyReason2Text", lang) }}
                    </div>
                  </div>
                </div>
                <div class="shucha-tips__item shucha-tips__item--inline">
                  <div class="shucha-tips__ico">
                    <img src="../../assets/img/ico/ico-primary-aportacion.svg" alt="" />
                  </div>
                  <div class="shucha-tips__main">
                    <div class="shucha-tips__title">{{ $t("piggybank.greatForFamilyReason3", lang) }}</div>
                    <div class="shucha-tips__subtitle">
                      {{ $t("piggybank.greatForFamilyReason3Text", lang) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </section>
    <section class="shucha shucha--grey" id="comoFunciona">
      <div class="bb-container">
        <div class="shucha__container">
          <header class="shucha__head">
            <div class="shucha__title">{{ $t("piggybank.howDoesItWork", lang) }}</div>
          </header>
          <main class="shucha__main">
            <div class="shucha-tips">
              <div class="shucha-tips__items">
                <div class="shucha-tips__item">
                  <div class="shucha-tips__ico">
                    <img src="../../assets/img/ico/ico-primary-hucha.svg" alt="" />
                  </div>
                  <div class="shucha-tips__main">
                    <div class="shucha-tips__title">{{ $t("piggybank.whatIsIt", lang) }}</div>
                    <div class="shucha-tips__description">
                      <p>
                        {{ $t("piggybank.whatIsIt1", lang) }}
                      </p>

                      <p>
                        {{ $t("piggybank.whatIsIt2", lang) }}
                      </p>

                      <p>
                        {{ $t("piggybank.whatIsIt3", lang) }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="shucha-tips__item">
                  <div class="shucha-tips__ico">
                    <img src="../../assets/img/ico/ico-primary-euro.svg" alt="" />
                  </div>
                  <div class="shucha-tips__main">
                    <div class="shucha-tips__title">
                      {{ $t("piggybank.howMuchIsIt", lang) }}
                    </div>
                    <div class="shucha-tips__description">
                      <p v-html="$t('piggybank.howMuchIsIt1', lang)"></p>
                      <ul>
                        <li>{{ $t("piggybank.howMuchIsIt11", lang) }}</li>
                        <li>{{ $t("piggybank.howMuchIsIt12", lang) }}</li>
                        <li>{{ $t("piggybank.howMuchIsIt13", lang) }}</li>
                      </ul>
                      <p>
                        {{ $t("piggybank.howMuchIsIt2", lang) }}
                      </p>
                      <ul>
                        <li>
                          {{ $t("piggybank.howMuchIsIt21", lang) }}
                        </li>
                        <li>{{ $t("piggybank.howMuchIsIt22", lang) }}</li>
                      </ul>
                      <!-- <p>{{ $t("piggybank.howMuchIsIt3", lang) }}</p>
                      <p>
                        {{ $t("piggybank.howMuchIsIt4", lang) }}
                      </p>

                      <ul>
                        <li>{{ $t("piggybank.howMuchIsIt41", lang) }}</li>
                        <li>{{ $t("piggybank.howMuchIsIt42", lang) }}</li>
                      </ul> -->
                      <p>
                        {{ $t("piggybank.howMuchIsIt5", lang) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </section>
    <section class="cta-crear-hucha" v-if="!isAuthenticated">
      <div class="bb-container">
        <div class="cta-crear-hucha__cta">
          <div class="cta-crear-hucha__title">{{ $t("piggybank.ctaMessage", lang) }}</div>
          <div class="cta-crear-hucha__actions">
            <bbButton :label='$t("piggybank.ctaStart", lang)' class="button--accent button--lg"
                      @click="$router.push({ path: $t('routes.signup', lang) })"></bbButton>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ModalCrear from "./components/ModalCrear.vue";
import { mapGetters } from "vuex";
import CrearStepIban from "./components/CrearStrepIban.vue";
import CrearStepDatos from "./components/CrearStrepDatos.vue";
import CrearStepConfirmar from "./components/CrearStrepConfirmar.vue";
import Notification from "../../components/ui/Notification.vue";
import bbButton from "../../components/buttons/bbButton.vue";
import ModalDisabled from "./components/ModalDisabled.vue";

export default {
  name: "HuchaCrear",
  metaInfo() {
    return {
      title: this.$t("piggybank.metatitle", this.lang),
      meta: [
        {
          name: "description",
          content:
            this.$t("piggybank.metadescription", this.lang)
        }
      ],
      link: [
        { rel: "canonical", href: "https://www.hellobb.net/hucha-digital" },
        { rel: "alternate", href: "https://www.hellobb.net/cagnotte-en-ligne", hreflang: "fr" },
        { rel: "alternate", href: "https://www.hellobb.net/salvadanaio-digitale", hreflang: "it" },
        { rel: "alternate", href: "https://www.hellobb.net/hucha-de-nacimiento", hreflang: "es-es" },
        { rel: "alternate", href: "https://www.hellobb.net/hucha-de-nacimiento", hreflang: "es-mx" },
        { rel: "alternate", href: "https://www.hellobb.net/hucha-de-nacimiento", hreflang: "es-co" },
        { rel: "alternate", href: "https://www.hellobb.net/hucha-de-nacimiento", hreflang: "es-ar" },
        { rel: "alternate", href: "https://www.hellobb.net/hucha-de-nacimiento", hreflang: "es-cl" },
        { rel: "alternate", href: "https://www.hellobb.net/hucha-de-nacimiento", hreflang: "es-uy" },
        { rel: "alternate", href: "https://www.hellobb.net/hucha-de-nacimiento", hreflang: "es-pe" },
      ],
    };
  },
  components: {
    Notification,
    ModalCrear,
    bbButton,
    ModalDisabled
  },
  computed: {
    ...mapGetters({
      user: "user",
      isAuthenticated: "isAuthenticated"
    }),
    lang()  {
      return this.$route.meta?.lang || this.$i18n.locale;
    }
  },
  data() {
    return {
      showErrorNotification: false,
      errorMessage: ""
    };
  },
  methods: {
    showError(errorMessage) {
      this.displayErrorNotification(errorMessage);
    },
    displayErrorNotification(errorMessage) {
      this.errorMessage = errorMessage;
      this.showErrorNotification = true;
    }
  },
  setup() {
    return {};
  },
  async mounted() {
    //patch for the app: if user has stripeAccountId, redirect to /hucha
    if (this.isAuthenticated) {
      var res = await this.$store.dispatch("stripeAccountExistsByEmail", {
        email: this.user.email
      });
      if (res.data) this.$router.push({ name: "hucha" });
    }
    //end patch

  }
};
</script>

<style lang="scss" scoped></style>
