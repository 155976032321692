<template>
    <div>
      <bbButton @click="showModal = true" :label="$t('piggybank.ctaStartUser')" :loading="loading"></bbButton>
  
      <modal-dialog
        :show="showModal"
        @close="showModal = false"
        target-class="modal__card--mini"
      >
        <div class="mch">
          <div class="mch__title">{{ $t("piggybank.disabledModalTitle") }}</div>
          <div class="mch__steps">
            {{ $t("piggybank.disabledModalDescription") }}
          </div>
        </div>
      </modal-dialog>
    </div>
  </template>
  
  <script>
  import ModalDialog from "../../../components/ui/ModalDialog.vue";
  import bbButton from "../../../components/buttons/bbButton.vue";
  
  export default {
    name: "ModalDisabled",
  
    components: {
      "modal-dialog": ModalDialog,
      bbButton,
    },
    data() {
      return {
        showModal: false,
        loading: false,
      };
    },
  };
  </script>
  
  <style lang="scss" scoped></style>
  