<template>
    <div>
      <header class="mch__form-header">
        <div class="mch__form-title">{{ $t("piggybank.createModal.makeListPrivate") }}</div>
      </header>
      <div class="bb-form-fieldset">
        <p>
          {{ $t("piggybank.createModal.makeListPrivateMsg") }}
        </p>
        <InputText v-model="pwd"
                   :label="$t('login.password')"
                   required />
      </div>
  
      <footer class="mch__actions">
        <button @click="backStep" class="button button--secondary button--icoStart">
          <i class="uil uil-arrow-left"></i> {{ $t("generic.back") }}
        </button>
        <button @click="nextStep"
                class="button button--primary button--block button--icoEnd"
                :disabled="!isFormValid()">
          {{ $t("generic.next") }} <i class="uil uil-arrow-right"></i>
        </button>
      </footer>
    </div>
  </template>
  
  <script>
  import InputText from "../../../components/forms/InputText.vue";
  import CrearDisclaimerStripe from "./CrearDisclaimer.vue";
  import { mapGetters } from "vuex";
  import InputSelect from "../../../components/forms/InputSelect.vue";
  
  export default {
    name: "CrearStepSeguridad",
    components: {
      InputSelect,
      CrearDisclaimerStripe,
      InputText
    },
    computed: {
      ...mapGetters({
        user: "user",
        getFirstStepData: "getFirstStepData",
        getSecondStepData: "getSecondStepData",
        countryCode: "countryCode",
      }),
      provinceIsRequired() {
        return this.countryCode === "ES";
      },
    },
    data() {
      return {
        pwd: "",
      };
    },
    created() {
      this.initFormData();
    },
    methods: {
      async nextStep() {

        const securityStepDat = {
          pwd: this.pwd,
        };
        
  
        if (this.pwd) {
          await this.$store.dispatch("saveListPwd", this.pwd);
          this.$emit("next-step", 4, securityStepDat);
        } else {
          console.error("Data is undefined or null");
        }
      },
      initFormData() {
        
      },
      backStep() {
        this.$emit("next-step", 2);
      },
      isFormValid() {
        return this.pwd.length >= 4
      },
    },
  };
  </script>
  
  <style lang="scss" scoped></style>
  