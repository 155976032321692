<template>
  <div>
    <header class="mch__form-header">
      <div class="mch__form-title">{{ $t("piggybank.createModal.confirmData") }}</div>
    </header>
    <div class="mch-confirma">
      <dl class="mch-confirma__list">
<!--        <dt>Cuenta bancaria (IBAN)</dt>
        <dd>{{ getFirstStepData.cuentaBancaria }}</dd>

        <dt>Pais</dt>
        <dd>España</dd>

        <dt>Moneda</dt>
        <dd>{{ getMonedaDisplay(getFirstStepData.moneda) }}</dd>-->

        <dt>{{ $t("generic.name") }}</dt>
        <dd>{{ getFirstStepData.nombre }}</dd>

        <dt>{{ $t("generic.surname") }}</dt>
        <dd>{{ getFirstStepData.apellidos }}</dd>

        <dt>{{ $t("generic.phoneNumber") }}</dt>
        <dd>{{ getFirstStepData.telefono }}</dd>

        <dt>{{ $t("generic.birthday") }}</dt>
        <dd>{{ getFirstStepData.fechaNacimiento }}</dd>

        <dt>{{ $t("generic.address") }}</dt>
        <dd>{{ getSecondStepData.direccion }}</dd>

        <dt>{{ $t("generic.city") }}</dt>
        <dd>{{ getSecondStepData.ciudad }}</dd>

        <dt>{{ $t("generic.postalCode") }}</dt>
        <dd>{{ getSecondStepData.codigoPostal }}</dd>

<!--        <dt>Email</dt>
        <dd>{{ getSecondStepData.email }}</dd>-->

      </dl>
    </div>

    <CrearDisclaimerStripe />

    <div class="mch__form-aceptance">
      <input
        type="checkbox"
        id="Terms"
        name="Terms"
        data-name="Terms"
        required
        v-model="termsvalue"
      />

      <label style="display: inline" for="Terms" class="checkbox-label w-form-label">
        {{ $t("piggybank.createModal.acceptTCpart1") }}
        <a class="link link--underline link--bold" target="_blank" :href="'/' + $t('routes.piggybankLegal')"
        >{{ $t("piggybank.createModal.acceptTCpart2") }}
        </a
        >
        .
      </label>
    </div>

    <div class="mch__form-aceptance">
      <input
        type="checkbox"
        id="useCaseAcceptation"
        name="useCaseAcceptation"
        data-name="userCaseAcceptation"
        required
        v-model="useCaseAcceptation"
      />

      <label style="display: inline" for="useCaseAcceptation" class="checkbox-label w-form-label">
        {{ $t("piggybank.createModal.acceptUseCase") }}
      </label>
    </div>

    <footer class="mch__actions">
      <button @click="backStep" class="button button--secondary button--icoStart">
        <i class="uil uil-arrow-left"></i> {{ $t("generic.back") }}
      </button>
      <bbButton
        :label="$t('piggybank.createModal.confirmData')"
        :disabled="!termsvalue || !useCaseAcceptation"
        :loading="loading"
        @click="finish"
        class="button button--primary button--block button--icoEnd"
      />
    </footer>
  </div>
</template>

<script>
import CrearDisclaimerStripe from "./CrearDisclaimer.vue";
import { mapGetters } from "vuex";
import bbButton from "../../../components/buttons/bbButton.vue";
import axios from "axios";
import { listStepPwd } from "../../../store/getters";

export default {
  name: "CrearStepConfirmar",
  components: {
    CrearDisclaimerStripe,
    bbButton
  },
  computed: {
    ...mapGetters({
      user: "user",
      getFirstStepData: "getFirstStepData",
      getSecondStepData: "getSecondStepData",
      countryCode: "countryCode",
      listStepPwd: "listStepPwd"
    })
  },
  data() {
    return {
      termsvalue: false,
      useCaseAcceptation: false,
      dia: "",
      mes: "",
      year: "",
      loading: false,
      isUserInSpain: false

    };
  },
  methods: {
    // checkUserLocation() {
    //   fetch("https://ipinfo.io/json?token=eac39e6354c467")
    //     .then(response => response.json())
    //     .then(data => {
    //       if (data.country !== "ES") {
    //         this.isUserInSpain = false;
    //       } else {
    //         this.isUserInSpain = true;
    //       }
    //     })
    //     .catch(error => {
    //       console.error("Error fetching IP geolocation data:", error);
    //       this.isUserInSpain = false;

    //     });
    //},
    backStep() {
      this.$emit("next-step", 3);
    },
    getMonedaDisplay(moneda) {
      switch (moneda) {
        case "eur":
          return "EURO (€)";
        case "dollar":
          return "DOLLAR ($)";
        case "jyp":
          return "Yen (円)";
        default:
          return "Moneda no especificada";
      }
    },
    async finish() {
        this.loading = true;

        const country = (this.countryCode === "FR" || this.countryCode === "IT" || this.countryCode === "BE" || this.countryCode === "CH" || this.countryCode === "LU")
          ? this.countryCode
          : "ES";

        const state = country === "ES"
          ? this.getSecondStepData.provincia
          : "";

        const telefono = this.$utils.formatPhoneNumber(this.getFirstStepData.telefono, country);

        const stripeUserData = {
          email: this.getSecondStepData.email,
          hbbId: this.user.id,
          childName: this.user.childName,
          FirstName: this.getFirstStepData.nombre,
          LastName: this.getFirstStepData.apellidos,
          Phone: telefono,
          Email: this.user.email,
          Address: {
            City: this.getSecondStepData.ciudad,
            Country: country,
            Line1: this.getSecondStepData.direccion,
            PostalCode: this.getSecondStepData.codigoPostal,
            State: state,
          },
          Dob: {
            Day: this.dia,
            Month: this.mes,
            Year: this.year
          },
          ExternalAccount: {
            AccountNumber: this.getFirstStepData.cuentaBancaria,
            Country: country,
            Currency: this.getFirstStepData.moneda,
            AccountHolderName: `${this.getFirstStepData.nombre} ${this.getFirstStepData.apellidos}`
          }
        };
        try {
            await this.$store.dispatch("patchListPwd", this.listStepPwd);
            var res = await this.$store.dispatch("createStripeAccount", stripeUserData);
            if (res.status === 200) {
              window.mixpanel.identify(this.user.id);
              window.mixpanel.track("Vidriola activada", { })
              window.mixpanel.people.set({
                "Vidriola status": "creada"
              });
            }
            this.$emit("finish");
        } catch (error) {
          let errorMessage = 'Hubo un error al crear la cuenta.';
          if (error.response && error.response.data) {
            errorMessage = error.response.data || errorMessage;
          }
          this.$emit('creation-error', errorMessage);
        }
      }
  },
  mounted() {
    //this.checkUserLocation();

    const [year, month, day] = this.getFirstStepData.fechaNacimiento.split("-").map(part => parseInt(part));
    this.year = year;
    this.mes = month;
    this.dia = day;

    console.log({ country: this.countryCode });
  }
};
</script>


<style lang="scss" scoped></style>
