<template>
  <div>
    <bbButton @click="show" :label="$t('piggybank.ctaStartUser')" :loading="loading"></bbButton>

    <modal-dialog
      :show="showModal"
      @close="showModal = false"
      target-class="modal__card--mini"
    >
      <div class="mch">
        <div class="mch__title">{{ $t("piggybank.ctaStart") }}</div>
        <div class="mch__steps">
          <Steps :steps="steps" :currentStep="currentStep" />
        </div>
        <div class="mch__form">
          <component
            :is="currentStepComponent"
            @next-step="nextStep"
            @finish="finish"
            @creation-error="handleCreationError"
          ></component>
        </div>
      </div>
    </modal-dialog>

    <transition name="bbFace" @after-enter="showSuccessModalContent = true">
      <div class="modal" v-show="showSuccessModal" @click="showContent = false">
        <transition name="bbUp" @after-leave="closeSuccessModal()">
          <div
            class="modal__card modal__card--mini"
            v-show="showSuccessModalContent"
            @click.stop=""
          >
            <div class="modal__dialog">
              <div class="modal__header">
                <div class="modal__close">
                  <img
                    src="../../../assets/img/ico/ico-close.svg"
                    alt="Cerrar"
                    @click="showSuccessModalContent = false"
                  />
                </div>
              </div>
              <div class="modal__content">
                <div class="card-success">
                  <div class="card-success__ico">
                    <img
                      src="../../../assets/img/ico/ico-color-success.svg"
                      alt="¡Gracias!"
                    />
                  </div>
                  <h3 class="card-success__header">{{ $t("piggybank.createModal.confirmationMessage") }}</h3>
                  <div class="card-success__content">
                    {{ $t("piggybank.createModal.confirmationMessage2" )}}
                  </div>
                  <div class="card-success__actions card-success__actions--vertical card-success__actions--50" v-if="isApp">
                    <router-link
                      :to="{ name: 'hucha' }"
                      class="button button--primary"
                    >
                      {{ $t("piggybank.createModal.view") }}
                    </router-link>
                  </div>
                  <div class="card-success__actions card-success__actions--vertical card-success__actions--50" v-else>
                    <router-link
                      to="/configuracion/mi-hucha"
                      class="button button--primary"
                    >
                      {{ $t("generic.configure") }}
                    </router-link>

                    <router-link
                      to="/mi-hucha"
                      class="button button--secondary"
                    >
                      {{ $t("piggybank.createModal.IWillDoItLater") }}
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </transition>

  </div>
</template>

<script>
import ModalDialog from "../../../components/ui/ModalDialog.vue";
import bbButton from "../../../components/buttons/bbButton.vue";

// Steps
import Steps from "./Steps.vue";

// Steps del procés de creació de la hucha
import CrearStepIban from "./CrearStrepIban.vue";
import CrearStepDatos from "./CrearStrepDatos.vue";
import CrearStepConfirmar from "./CrearStrepConfirmar.vue";
import CrearStepSeguridad from "./CrearStepSeguridad.vue";
import { mapGetters } from "vuex";

export default {
  name: "ModalCrear",

  components: {
    "modal-dialog": ModalDialog,
    Steps,
    CrearStepIban,
    CrearStepDatos,
    CrearStepConfirmar,
    CrearStepSeguridad,
    bbButton,
  },
  data() {
    return {
      showModal: false,
      showSuccessModal: false,
      showSuccessModalContent: false,
      currentStep: 1,
      loading: false,
      steps: [
        {
          step: 1,
          name: this.$t("piggybank.createModal.data"),
        },
        {
          step: 2,
          name: this.$t("piggybank.createModal.yourAddress"),
        },
        {
          step: 3,
          name: this.$t("piggybank.createModal.security"),
        },
        {
          step: 4,
          name: this.$t("piggybank.createModal.confirmData"),
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
      firstStepData: "getFirstStepData",
      isApp: "isApp",
    }),
    currentStepComponent() {
      switch (this.currentStep) {
        case 1:
          return CrearStepIban;
        case 2:
          return CrearStepDatos;
        case 3:
          return CrearStepSeguridad;
        case 4:
          return CrearStepConfirmar;
        default:
          return CrearStepIban;
      }
    },
  },
  methods: {
    show() {
      if (this.user.stripeAccountId) {
        this.$router.push("/mi-hucha");
      } else {
        this.showModal = true;
        window.mixpanel.track("Iniciar activació de vidriola");
      }
    },
    nextStep(nextStep) {
      this.currentStep = nextStep;
    },
    backStep(backStep) {
      this.currentStep = backStep;
    },
    finish() {
      this.showModal = false;
      this.showSuccessModal = true;
    },
    handleCreationError(errorMessage) {
      this.showModal = false;
      this.$emit('creation-error', errorMessage);
    },
    closeSuccessModal() {
      this.$router.push("/mi-hucha");
    },
  },
};
</script>

<style lang="scss" scoped></style>
